import { render, staticRenderFns } from "./AdminLayout.vue?vue&type=template&id=401ad5db&scoped=true"
import script from "./AdminLayout.vue?vue&type=script&lang=js"
export * from "./AdminLayout.vue?vue&type=script&lang=js"
import style0 from "./AdminLayout.vue?vue&type=style&index=0&id=401ad5db&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "401ad5db",
  null
  
)

export default component.exports